import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GlobalContext } from "./GlobalContext";

export default function GridNavLac({ navList }) {
  const { lang } = useContext(GlobalContext);
  return (
    <>
      <div className="mx-6 mb-16 grid min-h-full grid-cols-1 grid-rows-2 gap-6 md:grid-cols-2 md:grid-rows-1 lg:mx-20 lg:gap-20">
        {navList.map((element, index) => (
          <div key={index} className="!aspect-w-1 !aspect-h-1 relative flex">
            <GatsbyImage
              className="absolute inset-0 h-full w-full object-cover object-center"
              image={getImage(element.image)}
              alt=""
            />
            <div className="flex h-full w-full flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
              <h2 className="text-2xl font-medium text-white">
                {element.title}
              </h2>
              <div className="mt-1 text-lg font-medium text-white text-opacity-90">
                {renderRichText(element.description)}
              </div>
              <Link
                to={lang + element.subpageRedirectSlug}
                className="mt-4 rounded-md bg-white py-2.5 px-4 text-sm font-medium text-gray-900 hover:bg-gray-50"
              >
                {element.buttonText}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export const query = graphql`
  fragment GridNavLac on ContentfulNavigationPage {
    navListHeading
    navList {
      title
      description {
        raw
      }
      image {
        gatsbyImageData
      }
      buttonText
      subpageRedirectSlug
    }
  }
`;
