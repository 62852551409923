import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import Header from "../../../components/Header";
import GridNavLac from "../../../components/GridNavLac";

export default function NavigationPage({
  data: {
    page,
    contentfulNavigationBar,
    contentfulFooter,
  },
}) {
  const data = page;
  return (
    <Layout
      footerElements={contentfulFooter}
    >
      <Header
        heading={data.heading}
        subheading={data.subheading}
        buttonList={false}
        image={data.headerImage}
        navBar={contentfulNavigationBar.navigationBarList}
      />
      <div className="mx-auto my-24 max-w-3xl text-center">
        <h2 className="text-3xl tracking-wide text-ag-pink1 sm:text-4xl">
          {data.navListHeading}
        </h2>
      </div>
      <GridNavLac navList={data.navList} />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    page: contentfulNavigationPage(
      slug: { eq: $slug }
      node_locale: { eq: "de" }
    ) {
      ...HeaderWithoutButtonsNavigation
      ...GridNavLac
    }
    contentfulNavigationBar(node_locale: { eq: "de" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "de" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
